<template>
  <iframe v-if="url" allow="clipboard-read; clipboard-write" :src="url"></iframe>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialistsDirectory',
  mounted () {
    this.getUrl()
  },
  data () {
    return {
      country: 'CO',
      url: null
    }
  },
  methods: {
    goDetail (slug) {
      this.$router.push({ name: 'SpecialistDetail', params: { slug } })
    },
    getUrl () {
      window.addEventListener('message', (event) => {
        // handle the message from the iframe
        switch (event.data.action) {
          case 'goDetail':
            this.goDetail(event.data.data.slug)
            break
        }
      })
      this.url = `${process.env.VUE_APP_EAGLE_URL}/directory/${this.language.lang}/${this.country}?back=specialist-home`
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    })
  }
}
</script>

<style lang="sass" scoped>
iframe
  width: 100%
  height: 100vh
  position: fixed
  border-radius: 0 !important
  outline: unset !important
</style>
